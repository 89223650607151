@import '~react-alice-carousel/lib/alice-carousel.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply md:text-base;

    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-display;
  }
}
#__next {
  @apply h-full;
}
.post-description * {
  margin-top: 1.5rem;
}

.post-description p img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.card-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.markdown {
  @apply leading-relaxed;
}

.cookie-banner {
  max-height: calc(100% - 5.45rem);
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown blockquote {
  @apply my-6 text-sm md:text-base;
}

.markdown a {
  @apply text-orange focus:text-orange hover:text-orange outline-none cursor-pointer;
}
.markdown blockquote {
  @apply mb-24 px-6 py-2 bg-beige-200 md:p-10;
}

.markdown blockquote p {
  @apply my-0;
}

.markdown blockquote p:nth-child(2n) + p {
  @apply mt-4;
}

.markdown h2 {
  @apply my-8 font-body text-lg font-bold leading-snug;
}

.markdown strong {
  @apply font-bold;
}
.markdown ol {
  @apply list-decimal;
}
.markdown ol li,
.markdown ul li {
  @apply my-8 font-body text-lg font-bold leading-snug list-inside md:text-xl;
}

.contact-page {
  grid-template-columns: 1fr 1fr;
}

.contact-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.contact-shadow .icon svg path {
  stroke-width: 16;
}
.header-bg-image::after {
  content: '';
  background-size: auto 100%;

  @apply absolute left-0 top-0 w-full h-full bg-none bg-left-top bg-no-repeat lg:bg-cinema-header;
}

.hero-image::after {
  @apply md:bg-hero-header;
}
